var contrastToggle = document.querySelector('.contrast-toggle');

contrastToggle.addEventListener('click', function (e) {

    var currentMode = document.documentElement.classList.contains('light-mode') ? 'light' : 'dark';
    var title = e.target.getAttribute('title');
    var titleOpts = ['dark', 'light'];

    (currentMode === 'light')
        ? e.target.setAttribute('title', title.replace(...titleOpts))
        : e.target.setAttribute('title', title.replace(...titleOpts.reverse()));

    document.documentElement.classList.toggle('light-mode');

});

if ('IntersectionObserver' in window) {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting === true) {
                entry.target.classList.add('scrolled-past');
            }
        });
    }, {
        threshold: 0.15
    });

    const items = document.querySelectorAll('.block');

    items.forEach((item, index) => {
        observer.observe(item, index);
    });

}
else {
    document.documentElement.classList.add('no-intersection-observer');
}